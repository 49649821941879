#ez-toc-container {
  background: #f4f7f9;
  color: #25383f;
  display: table;
  display: block;
  padding: 32px 0;
  position: relative;
  width: auto;
  margin: 32px 0;
}

div.ez-toc-widget-container {
  padding: 0;
  position: relative;
}

#ez-toc-container.ez-toc-light-blue {
  background: #edf6ff;
}

#ez-toc-container.ez-toc-white {
  background: #ffffff;
}

#ez-toc-container.ez-toc-black {
  background: #000000;
}

#ez-toc-container.ez-toc-transparent {
  background: none transparent;
}

div.ez-toc-widget-container ul {
  display: block !important;
}

div.ez-toc-widget-container li {
  border: none !important;
  padding: 0 !important;
}

div.ez-toc-widget-container ul.ez-toc-list {
  padding: 10px !important;
}

#ez-toc-container nav {
  margin-left: 34px;
}

#ez-toc-container ul {
  margin: 0 0 0 16px !important;
}

#ez-toc-container li {
  font-size: 0.875rem !important;
  line-height: 30.2px !important;
  margin-bottom: 0 !important;
  padding: 0 !important;
  margin-left: inherit;
}

#ez-toc-container ul,
#ez-toc-container li,
#ez-toc-container ul li,
div.ez-toc-widget-container,
div.ez-toc-widget-container li {
  z-index: 1;
}

#ez-toc-container .ez-toc-title {
  text-align: left;
  padding: 0;
  font-weight: 700;
  font-size: 0.875rem !important;
  line-height: 25.2px !important;
}

.ez-toc-title-container {
  display: table;
  width: 100%;
  margin: 0 0 12px 40px;
}

.ez-toc-title,
.ez-toc-title-toggle {
  display: inline;
  text-align: left;
  vertical-align: middle;
}

.ez-toc-title-toggle {
  display: none;
}

#ez-toc-container.ez-toc-black p.ez-toc-title {
  color: #fff;
}

#ez-toc-container div.ez-toc-title-container + ul.ez-toc-list {
  margin-top: 1em;
}

.ez-toc-wrap-left {
  margin: 0px auto 1em 0px !important;
}
.ez-toc-wrap-left-text {
  float: left;
}

.ez-toc-wrap-right {
  margin: 0px 0px 1em auto !important;
}
.ez-toc-wrap-right-text {
  float: right;
}

#ez-toc-container a {
  box-shadow: none;
  text-shadow: none;
  display: inline-flex;
  align-items: stretch;
  flex-wrap: nowrap;
  font-size: 0.875rem !important;
  line-height: 25.2px !important;
}

/* #ez-toc-container a:visited { */
/* color: #9f9f9f; */
/* } */

/* #ez-toc-container a:hover { */
/* text-decoration: underline; */
/* } */

#ez-toc-container.ez-toc-black a {
  color: #fff;
}

#ez-toc-container.ez-toc-black a:visited {
  color: #fff;
}

#ez-toc-container a.ez-toc-toggle {
  display: flex;
  align-items: center;
  color: #444444;
}

/* .ez-toc-widget-container ul.ez-toc-list li::before { */
/* content: ' '; */
/* position: absolute; */
/* left: 0; */
/* right: 0; */
/* height: 30px; */
/* line-height: 30px; */
/* z-index: -1; */
/* } */

.ez-toc-widget-container ul.ez-toc-list li.active {
  background-color: #ededed;
}

.ez-toc-widget-container li.active > a {
  font-weight: 900;
}

.ez-toc-btn {
  display: inline-block;
  padding: 6px 12px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.428571429;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 4px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.ez-toc-btn:focus {
  outline: thin dotted #333;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}

.ez-toc-btn:hover,
.ez-toc-btn:focus {
  color: #333;
  text-decoration: none;
}

.ez-toc-btn:active,
.ez-toc-btn.active {
  background-image: none;
  outline: 0;
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}

.ez-toc-btn-default {
  color: #333;
  background-color: #fff;
  border-color: #ccc;
}

.ez-toc-btn-default:hover,
.ez-toc-btn-default:focus,
.ez-toc-btn-default:active,
.ez-toc-btn-default.active {
  color: #333;
  background-color: #ebebeb;
  border-color: #adadad;
}

.ez-toc-btn-default:active,
.ez-toc-btn-default.active {
  background-image: none;
}

.ez-toc-btn-sm,
.ez-toc-btn-xs {
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px;
}

.ez-toc-btn-xs {
  padding: 1px 5px;
}

.ez-toc-btn-default {
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}

.ez-toc-btn-default:active {
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}

.ez-toc-btn:active,
.btn.active {
  background-image: none;
}

.ez-toc-btn-default {
  text-shadow: 0 1px 0 #fff;
  background-image: -webkit-gradient(linear, left 0, left 100%, from(#fff), to(#e0e0e0));
  background-image: -webkit-linear-gradient(top, #fff 0, #e0e0e0 100%);
  background-image: -moz-linear-gradient(top, #fff 0, #e0e0e0 100%);
  background-image: linear-gradient(to bottom, #fff 0, #e0e0e0 100%);
  background-repeat: repeat-x;
  border-color: #dbdbdb;
  border-color: #ccc;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffffff', endColorstr='#ffe0e0e0', GradientType=0);
  filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
}

.ez-toc-btn-default:hover,
.ez-toc-btn-default:focus {
  background-color: #e0e0e0;
  background-position: 0 -15px;
}

.ez-toc-btn-default:active,
.ez-toc-btn-default.active {
  background-color: #e0e0e0;
  border-color: #dbdbdb;
}

.ez-toc-pull-right {
  float: right !important;
  margin-left: 10px;
}

#ez-toc-container label.cssicon,
#ez-toc-widget-container label.cssicon {
  height: 30px;
}

.ez-toc-glyphicon {
  position: relative;
  top: 1px;
  display: inline-block;
  font-family: 'Glyphicons Halflings';
  -webkit-font-smoothing: antialiased;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -moz-osx-font-smoothing: grayscale;
}

.ez-toc-glyphicon:empty {
  width: 1em;
}

.ez-toc-toggle i.ez-toc-glyphicon {
  font-size: 16px;
  margin-left: 2px;
}

#ez-toc-container input {
  position: absolute;
  left: -999em;
}

#ez-toc-container input[type='checkbox']:checked + nav,
#ez-toc-widget-container input[type='checkbox']:checked + nav {
  opacity: 0;
  max-height: 0;
  border: none;
  display: none;
}
#ez-toc-container label {
  position: relative;
  cursor: pointer;
  display: initial;
}

#ez-toc-container .ez-toc-toggle label {
  float: right;
  position: relative;
  font-size: 16px;
  padding: 0;
  border: 1px solid #999191;
  border-radius: 5px;
  cursor: pointer;
  left: 10px;
  width: 35px;
}

div#ez-toc-container .ez-toc-title {
  display: initial;
}

div#ez-toc-container {
  padding-right: 20px;
}
.ez-toc-wrap-center {
  margin: 0 auto 1em auto !important;
}

#ez-toc-container a.ez-toc-toggle {
  color: #444444;
  background: inherit;
  border: inherit;
}

.ez-toc-toggle #item {
  position: absolute;
  left: -999em;
}

label.cssicon .ez-toc-glyphicon:empty {
  font-size: 16px;
  margin-left: 2px;
}

label.cssiconcheckbox {
  display: none;
}

.ez-toc-widget-container ul li a {
  padding-left: 10px;
  display: inline-flex;
  align-items: stretch;
  flex-wrap: nowrap;
}

.ez-toc-widget-container ul.ez-toc-list li {
  height: auto !important;
}
.ez-toc-cssicon {
  float: right;
  position: relative;
  font-size: 16px;
  padding: 0;
  border: 1px solid #999191;
  border-radius: 5px;
  cursor: pointer;
  left: 10px;
  width: 35px;
}
.ez-toc-icon-toggle-span {
  display: flex;
  align-items: center;
  width: 35px;
  height: 30px;
  justify-content: center;
  direction: ltr;
}
#ez-toc-container .eztoc-toggle-hide-by-default {
  display: none;
}

/* Richtext editors classes */

/* see the RichText component for alternative ways to center text by applying text-align to the parent */
.text-align-center {
  text-align: center;
  display: block; /* Because the elements are usually inline */
}

.no-underline *,
a.chakra-link:has(.no-underline),
a.chakra-link:has(.no-underline) * {
  text-decoration: none !important;
  -webkit-text-decoration: none !important;
  -webkit-text-decoration: none !important;
  -moz-text-decoration: none !important;
  -ms-text-decoration: none !important;
}

.text-font-lg,
.text-font-lg p,
.text-font-lg span {
  font-size: 1.25rem;
}

.large-button-child a {
  width: 15rem;
  height: 3.5rem;
}

/* Target p elements that come immediately before .rich-text-inline */
p:has(+ .rich-text-inline),
/* Target p elements that come immediately after .rich-text-inline */
.rich-text-inline + p {
  display: inline;
}
